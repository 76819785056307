import { zodResolver } from "@hookform/resolvers/zod";
import { Delete, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { ActivityEditorData } from "components/feed/ActivityEditor/ActivityEditorDialog";
import Audience from "components/feed/ActivityEditor/Audience";
import DatePicker from "components/shared/DatePicker";
import DialogHeader from "components/shared/DialogHeader";

import MemberSearchField from "components/shared/MemberSearchField";
import TheDroplet from "components/shared/TheDroplet";
import { TimeZoneSelect } from "components/shared/TimeZoneSelect";
import { useApp } from "lib/common/appProvider";
import textFieldRegister from "lib/common/textFieldRegister";
import { getTotalMinutes } from "lib/date/getTotalMinutes";

import {
  FeedEventCreateInput,
  FeedEventUpdateInput,
  useEvent,
} from "lib/motorcade/hooks/useEvent";
import { AlertCallback, FeedActivity } from "lib/motorcade/types";

import {
  DESCRIPTION_MAX_LENGTH,
  EventValidationSchemaType,
  TITLE_MAX_LENGTH,
  buttonRecordingResourcesOptions,
  buttonTextOptions,
  eventTypeOptions,
  eventValidationSchema,
  getFormInitialValues,
  initialValues,
} from "./utils";

type Props = {
  activity?: FeedActivity & {
    verb: "event";
  };
  activityEditorData: ActivityEditorData;
  onClose: () => void;
  onReset: () => void;
  canSelectAudience: boolean;
  setActivityEditorData: Dispatch<SetStateAction<ActivityEditorData>>;
  setIsDirty: (value: boolean) => void;
};

export default function EventEditorDialog({
  activity,
  onClose,
  onReset,
  canSelectAudience,
  activityEditorData,
  setActivityEditorData,
  setIsDirty,
}: Props) {
  const defaultValues = activity
    ? getFormInitialValues(activity)
    : initialValues;

  const [imageURL, setImageURL] = useState(() => activity?.mediaUrl ?? null);

  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const { addEvent, addEventLoading, updateEvent } = useEvent(
    activity,
    alertCallback
  );

  const editing = !!activity;
  const dialogTitle = editing ? "Edit event" : "Create event";
  const buttonText = editing ? "Edit event" : "Post event";

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues,
    resolver: zodResolver(eventValidationSchema),
  });

  const updateStartTime = (newValue) => {
    setValue("startTime", newValue);

    if (getValues("endTime")) {
      const startTimeTotalMinutes = getTotalMinutes(getValues("startTime"));
      const endTimeTotalMinutes = getTotalMinutes(getValues("endTime"));

      if (
        startTimeTotalMinutes &&
        endTimeTotalMinutes &&
        startTimeTotalMinutes >= endTimeTotalMinutes
      ) {
        setValue("endTime", newValue);
      }
    }
  };

  const updateEndTime = (newValue: string) => {
    setValue("endTime", newValue);

    const startTimeTotalMinutes = getTotalMinutes(getValues("startTime"));
    const endTimeTotalMinutes = getTotalMinutes(getValues("endTime"));

    if (
      startTimeTotalMinutes &&
      endTimeTotalMinutes &&
      startTimeTotalMinutes >= endTimeTotalMinutes
    ) {
      setValue("startTime", newValue);
    }
  };

  const toggleEndtime = (checked: boolean) => {
    if (checked) {
      const startTimeHours = getValues("startTime").substring(0, 2);
      const startTimeMinutes = getValues("startTime").substring(3);

      const newEndTimeHours = Number(startTimeHours) + 2;
      const newEndTimeHoursString = newEndTimeHours.toString().padStart(2, "0");

      if (newEndTimeHours >= 24) {
        setValue("endTime", "23:59");
      } else {
        setValue("endTime", `${newEndTimeHoursString}:${startTimeMinutes}`);
      }
    } else {
      setValue("endTime", defaultValues.endTime);
    }
  };

  const reader = useMemo(() => new FileReader(), []);

  const handleImage = (file) => {
    setValue("attachment", file);
    reader.readAsDataURL(file);
  };

  const handleImageRemove = () => {
    setValue("attachment", null);
    setImageURL(null);
  };

  const onSubmit: SubmitHandler<EventValidationSchemaType> = async (data) => {
    if (activity) {
      const { host, ...inputData } = data;
      const input: FeedEventUpdateInput = {
        ...inputData,
        eventId: activity.object.id,
        timezone: data.timezone.name,
        startDate: data.startDate.toISOString(),
        dataUri: reader?.result ?? null,
        imageURL,
      };
      if (host) {
        input.host = {
          userId: host.userId,
          name: host.name,
          avatar: host.avatar,
        };
      }
      updateEvent(input);
    } else {
      const { host, ...inputData } = data;
      const input: FeedEventCreateInput = {
        ...inputData,
        timezone: data.timezone.name,
        startDate: data.startDate.toISOString(),
        dataUri: imageURL,
      };
      if (host) {
        input.host = {
          userId: host.userId,
          name: host.name,
          avatar: host.avatar,
        };
      }
      if (data.audience === "general") {
        input.audience = "GENERAL";
      } else if (data.audience === "group") {
        input.audience = "GROUP";
        input.group = activityEditorData?.group;
      }
      if (!data.startTime) delete input.startTime;
      if (!data.endTime) delete input.endTime;
      await addEvent(input);
    }
    onReset();
  };

  useEffect(() => {
    const handleLoad = () => setImageURL(reader.result as string);
    reader.addEventListener("load", handleLoad, false);

    return () => reader.removeEventListener("load", handleLoad);
  }, [activity?.mediaUrl, reader]);

  useEffect(() => {
    setValue("audience", activityEditorData.audience);
  }, [activityEditorData, setValue]);

  useEffect(() => {
    if (isDirty) {
      setIsDirty(true);
    }
  }, [isDirty, setIsDirty]);

  return (
    <>
      <DialogHeader title={dialogTitle} onClose={onClose} />

      <DialogContent>
        <Box mb={2}>
          <Typography variant="h3">Basic info</Typography>
          <Typography color="textSecondary" variant="caption">
            Provide basic details about your event.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Audience <span>*</span>
          </Typography>

          <Audience
            activityEditorData={activityEditorData}
            disabled={!canSelectAudience}
            excludeOptions={["profile"]}
            setActivityEditorData={setActivityEditorData}
            setIsDirty={setIsDirty}
          />
        </Box>

        <Box mb={2}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Event Title <span>*</span>
          </Typography>

          <TextField
            fullWidth
            multiline
            error={!!errors?.title}
            helperText={errors?.title ? errors?.title.message : ""}
            placeholder="Be clear and concise…"
            rows={2}
            size="small"
            variant="outlined"
            {...textFieldRegister({ name: "title", register })}
          />
          <Box display="flex" justifyContent="flex-end" mt={0.5}>
            <Typography
              color={
                watch("title")?.length > TITLE_MAX_LENGTH
                  ? "error"
                  : "textPrimary"
              }
              variant="caption"
            >{`${watch("title")?.length}/${TITLE_MAX_LENGTH}`}</Typography>
          </Box>
        </Box>

        <Box mb={2}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Event Description <span>*</span>
          </Typography>

          <TextField
            fullWidth
            multiline
            error={!!errors?.description}
            helperText={errors?.description ? errors?.description.message : ""}
            placeholder="Provide more information so your audience knows what to expect..."
            rows={2}
            size="small"
            variant="outlined"
            {...textFieldRegister({ name: "description", register })}
          />
          <Box display="flex" justifyContent="flex-end" mt={0.5}>
            <Typography
              color={
                watch("description")?.length > DESCRIPTION_MAX_LENGTH
                  ? "error"
                  : "textPrimary"
              }
              variant="caption"
            >{`${
              watch("description")?.length
            }/${DESCRIPTION_MAX_LENGTH}`}</Typography>
          </Box>
        </Box>

        <Box mb={2}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Type <span>*</span>
          </Typography>

          <TextField
            fullWidth
            select
            error={!!errors?.type}
            helperText={errors?.type ? errors?.type.message : ""}
            size="small"
            value={watch("type")}
            {...textFieldRegister({ name: "type", register })}
          >
            {eventTypeOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box mb={2}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Host
          </Typography>

          <MemberSearchField
            multiple={false}
            placeholder="Add a host..."
            queryUsersLoading={false}
            selectedUsers={watch("host")}
            setSelectedUsers={(newValue) => setValue("host", newValue)}
            startAdornment={<Search />}
          />
        </Box>

        <Box mb={3}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Featured Photo
          </Typography>

          <Box
            sx={{
              border: "1px dashed #333333",
              borderRadius: "4px",
            }}
          >
            {imageURL ? (
              <Box position="relative">
                <img
                  alt="Event"
                  height="300px"
                  src={imageURL}
                  style={{
                    display: "block",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  width="100%"
                />

                <IconButton
                  color="primary"
                  sx={{ position: "absolute", top: "10px", right: "8px" }}
                  onClick={handleImageRemove}
                >
                  <Delete />
                </IconButton>
              </Box>
            ) : (
              <TheDroplet
                buttonText="Upload Photo"
                imageMinHeight={675}
                imageMinWidth={1200}
                subText="At least 1200 x 675 pixels"
                validFileTypes={["image/jpeg", "image/jpg", "image/png"]}
                onChange={handleImage}
              />
            )}
          </Box>
        </Box>

        <Box mb={2}>
          <Typography variant="h3">Date and time</Typography>
          <Typography color="textSecondary" variant="caption">
            Select when your event will occur.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Timezone <span>*</span>
          </Typography>

          <TimeZoneSelect
            fullWidth
            error={!!errors?.timezone}
            helperText={errors?.timezone ? errors?.timezone.message : ""}
            size="small"
            {...register("timezone")}
            value={watch("timezone")}
            onChange={(e, newValue) => setValue("timezone", newValue)}
          />
        </Box>

        <Box mb={3}>
          <Box display="flex" gap="16px">
            <Box width="50%">
              <Typography
                sx={{
                  textTransform: "uppercase",
                  marginBottom: "8px",

                  "& span": {
                    color: (theme) => theme.palette.error.main,
                  },
                }}
                variant="body2"
              >
                Start Date <span>*</span>
              </Typography>

              <DatePicker
                error={!!errors?.startDate}
                helperText={errors?.startDate ? errors?.startDate.message : ""}
                inputFormat="eee, MMM d, y"
                value={new Date(watch("startDate"))}
                onChange={(newValue) => setValue("startDate", newValue)}
              />
            </Box>

            <Box display="flex" gap="8px" width="50%">
              <div>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    marginBottom: "8px",

                    "& span": {
                      color: (theme) => theme.palette.error.main,
                    },
                  }}
                  variant="body2"
                >
                  Start Time
                </Typography>

                <TextField
                  fullWidth
                  error={!!errors?.startTime}
                  helperText={
                    errors?.startTime ? errors?.startTime.message : ""
                  }
                  placeholder="Start time"
                  size="small"
                  sx={{
                    "& input::-webkit-calendar-picker-indicator": {
                      background: "transparent",
                      bottom: 0,
                      color: "transparent",
                      cursor: "pointer",
                      height: "auto",
                      left: 0,
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "auto",
                    },
                  }}
                  type="time"
                  variant="outlined"
                  {...textFieldRegister({ name: "startTime", register })}
                  onChange={(e) => updateStartTime(e.target.value)}
                />
              </div>

              <div>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    marginBottom: "8px",
                    opacity: !watch("endTime") ? "50%" : "100%",
                    "& span": {
                      color: (theme) => theme.palette.error.main,
                    },
                  }}
                  variant="body2"
                >
                  End Time
                </Typography>

                <TextField
                  disabled={!watch("endTime")}
                  placeholder="End time"
                  size="small"
                  sx={{
                    "& input::-webkit-calendar-picker-indicator": {
                      background: "transparent",
                      bottom: 0,
                      color: "transparent",
                      cursor: "pointer",
                      height: "auto",
                      left: 0,
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "auto",
                    },
                  }}
                  type="time"
                  variant="outlined"
                  {...textFieldRegister({ name: "endTime", register })}
                  onChange={(e) => updateEndTime(e.target.value)}
                />
              </div>
            </Box>
          </Box>

          <FormControlLabel
            control={<Checkbox checked={!!watch("endTime")} />}
            label="Add end time"
            sx={{ ml: "4px" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              toggleEndtime(e.target.checked)
            }
          />
        </Box>

        <Box mb={2}>
          <Typography variant="h3">Event webpage</Typography>
          <Typography color="textSecondary" variant="caption">
            Provide the link to your event and customize the button text.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Button Text <span>*</span>
          </Typography>

          <TextField
            fullWidth
            select
            error={!!errors?.cta}
            helperText={errors?.cta ? errors?.cta.message : ""}
            size="small"
            value={watch("cta")}
            {...textFieldRegister({ name: "cta", register })}
          >
            {buttonTextOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box mb={3}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Link <span>*</span>
          </Typography>

          <TextField
            fullWidth
            error={!!errors?.link}
            helperText={errors?.link ? errors?.link.message : ""}
            placeholder="URL..."
            size="small"
            variant="outlined"
            {...textFieldRegister({ name: "link", register })}
          />
        </Box>

        <Box mb={2}>
          <Typography variant="h3">Event recording/resources info</Typography>
          <Typography color="textSecondary" variant="caption">
            Select recording/resources button text and provide URL for
            resources.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Button Text
          </Typography>

          <TextField
            fullWidth
            select
            error={!!errors?.resourceCta}
            helperText={errors?.resourceCta ? errors?.resourceCta.message : ""}
            size="small"
            value={watch("resourceCta")}
            {...textFieldRegister({ name: "resourceCta", register })}
          >
            {buttonRecordingResourcesOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box mb={3}>
          <Typography
            sx={{
              textTransform: "uppercase",
              marginBottom: "8px",

              "& span": {
                color: (theme) => theme.palette.error.main,
              },
            }}
            variant="body2"
          >
            Link
          </Typography>

          <TextField
            fullWidth
            error={!!errors?.resourceLink}
            helperText={
              errors?.resourceLink ? errors?.resourceLink.message : ""
            }
            placeholder="URL..."
            size="small"
            variant="outlined"
            {...textFieldRegister({
              name: "resourceLink",
              register,
            })}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Discard</Button>
        <LoadingButton
          loading={addEventLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {buttonText}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
